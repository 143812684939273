<template>
    <div class="send-container">
        <van-cell-group inset>
            <van-field v-model="name" name="姓名" label="姓名" placeholder="请输入姓名"
                :rules="[{ required: true, message: '请输入姓名' }]" required/>
            <van-field v-model="phone" name="手机" label="手机" placeholder="请输入手机"
                :rules="[{ required: true, message: '请输入手机' }]" required/>
            <van-field v-model="email" name="邮箱" label="邮箱" placeholder="请输入邮箱"
                :rules="[{ required: true, message: '请输入邮箱' }]" required/>
            <van-field name="uploader" label="简历上传" :required="isMustFile === 1">
                <template #input>
                    <van-uploader :max-count="1" v-model="file" :after-read="handleUpload" accept=".pdf" @delete="deleteFile">
                        <van-button icon="plus" class="btn">上传简历</van-button>
                    </van-uploader>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit" class="btn submit-btn" @click="handleSubmit">
                    提交
                </van-button>
            </div>
        </van-cell-group>
    </div>
</template>
<script>
import { getJobDetail,submitJoin } from '@/api/index.js';
import { Toast, Notify } from 'vant';
import { CHANNEL,CHANNELCODE,RECOMMEND_Id } from '@/utils/constants';
import { getSessionStorage } from '@/utils/storage'
import axios from 'axios';
export default {
    data() {
        return {
            isMustFile:0,
            name: '',
            email: '',
            phone: '',
            file: [],
            fileData: null
        }
    },
    methods: {
        getData() {
            const id = this.$route.params.id || '';
            if (!id) return;
            getJobDetail(id).then(res => {
                this.isMustFile = res.isMustFile;
            });
        },
        handleUpload(e) {
            console.log('e: ', e);
            const name = e.file.name;
            const content = e.file;
            const data = new FormData();
            data.append('file', content, name);

            const config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            axios.post('/sys/front/file/upload', data, config).then(res => {
               if(res.data.code === 'ok') {
                    this.fileData = res.data.data;
               }
            })
        },
        deleteFile() {
            this.fileData = null;
        },
        handleSubmit() {
            if(!this.name) {
                Notify({ type: 'danger', message: '请输入姓名！' });
                return;
            }
            // 验证电话号码
            if(!this.phone) {
                Notify({ type: 'danger', message: '请输入电话号码！' });
                return;
            }
            const phoneReg=/^[1][3,4,5,7,8][0-9]{9}$/;
            if(!phoneReg.test(this.phone)) {
                Notify({ type: 'danger', message: '请输入正确的电话号码！' });
                return;
            }
            // 验证邮箱
            if(!this.email) {
                Notify({ type: 'danger', message: '请输入邮箱！' });
                return;
            }
            // 附件
            if(!this.fileData && this.isMustFile === 1) {
                Notify({ type: 'danger', message: '请上传简历！' });
                return;
            }

            const form = {
                recruitId: this.$route.params.id,
                name: this.name,
                email: this.email,
                phone: this.phone,
                fileVos: this.fileData?[this.fileData]:[],
                channel: CHANNELCODE[getSessionStorage(CHANNEL)],
                recommendId: getSessionStorage(RECOMMEND_Id),
            }
            submitJoin(form).then(() => {
                Toast.success('投递成功');
                this.$router.replace({path: '/joinUs', query: { channel: getSessionStorage(CHANNEL) || 'official'}})
            });
        }
    },
    mounted() {
        this.getData();
    }
}
</script>
<style lang="scss" scoped>
.send-container {
    min-height: calc(100vh - 91px);

    .btn {
        background-color: #005554;
        border: 1px solid #005554;
        color: #fff;
        height: 84rem;
        line-height: 84rem;
    }

    .submit-btn {
        margin-top: 36rem;
    }

}
</style>
